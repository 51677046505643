<template>
    <div>
        <nav-index :title="'预购'"/>
        <div class="booking-box">
            <div class="list">
                <div class="title">您有意向购买以下哪些商品？</div>
                <panel :lists="lists" class="pannnel" @openPanel="openPanel" @selectOption="selectOption" @selectPanel="selectPanel"/>
                <div class="button-block">
                    <div class="button-plain-lg" v-if="isSubmitted">提 交</div>
                    <div class="button-primary-lg" @click="handleClick" v-else>提 交</div>
                </div>
            </div>
        </div>
        <!-- <Main v-if="$store.state.userAgent === 'Mobile'"></Main> -->
    </div>
</template>

<script>
// import Main from '../../components/footer/Main.vue'
import Panel from '../../components/panel/index.vue'
import NavIndex from '../../components/nav/index.vue'

export default {
  name: 'Booking',
  components: {
    // Main,
    Panel,
    NavIndex
  },
  created(){
    this.tempList = this.lists
  },
  mounted(){

  },
  data:()=>{
    return {
      isSubmitted: true,
      tempList: [],
      selectedProd:[],
      lists:[{
            id:'1',
            title:'应急照明控制器',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcda1',
                text: '立柜式',
                selected: false
            },{
                id:'abcda2',
                text: '壁挂式',
                selected: false
            }]
        },{
            id:'2',
            title:'应急照明集中电源(分布式)',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdb1',
                text: '壁挂式24V铅酸蓄电池',
                selected: false
            },{
                id:'abcdb2',
                text: '壁挂式36V铅酸蓄电池',
                selected: false
            }]
        },{
            id:'3',
            title:'应急照明集中电源(一站式)',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdc1',
                text: '立柜式',
                selected: false
            }]
        },{
            id:'4',
            title:'应急照明分配电装置',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdd1',
                text: '壁挂式',
                selected: false
            }]
        },{
            id:'5',
            title:'集控集电LED标志灯具',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcde1',
                text: '壁挂式',
                selected: false
            },{
                id:'abcde2',
                text: '嵌入式',
                selected: false
            },{
                id:'abcde3',
                text: '吊装式',
                selected: false
            },{
                id:'abcde4',
                text: '地埋式',
                selected: false
            },{
                id:'abcde5',
                text: '三角形壁挂式',
                selected: false
            },{
                id:'abcde6',
                text: '防爆款壁挂式',
                selected: false
            }]
        },{
            id:'6',
            title:'集控集电壁挂式LED双头灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdf1',
                text: '防爆款',
                selected: false
            }]
        },{
            id:'7',
            title:'集控集电嵌入式LED筒灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdg1',
                text: '人体感应款',
                selected: false
            },{
                id:'abcdg2',
                text: '雷达感应款',
                selected: false
            }]
        },{
            id:'8',
            title:'集控集电明装LED筒灯',
            showOptions: false,
            selected: false,
            options:[]
        },{
            id:'9',
            title:'集控集电LED射灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'10',
            title:'集控集电LED防眩天花射灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'11',
            title:'集控集电LED格栅射灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdh1',
                text: 'TR单头格栅射灯',
                selected: false
            },{
                id:'abcdh2',
                text: 'GG单头格栅射灯',
                selected: false
            },{
                id:'abcdh3',
                text: '双头格栅射灯',
                selected: false
            }]
        },{
            id:'12',
            title:'集控集电LED条形天花灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'13',
            title:'集控集电LED轨道灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'14',
            title:'集控集电LED投光灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'15',
            title:'集控集电LED吸顶灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdi1',
                text: '声光控感应款',
                selected: false
            },{
                id:'abcdi2',
                text: '人体感应款',
                selected: false
            },{
                id:'abcdi3',
                text: '雷达感应款',
                selected: false
            }]
        },{
            id:'16',
            title:'集控集电LEDT8支架灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdj1',
                text: '人体感应款',
                selected: false
            },{
                id:'abcdj2',
                text: '雷达感应款',
                selected: false
            }]
        },{
            id:'17',
            title:'集控集电三防LEDT8支架灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'18',
            title:'LED消防应急照明筒灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdk1',
                text: '持续型',
                selected: false
            },{
                id:'abcdk2',
                text: '带强制点亮端口持续型',
                selected: false
            },{
                id:'abcdk3',
                text: '人体感应带强制点亮端口非持续型',
                selected: false
            },{
                id:'abcdk4',
                text: '雷达感应带强制点亮端口非持续型',
                selected: false
            }]
        },{
            id:'19',
            title:'LED消防应急照明吸顶灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdl1',
                text: '持续型',
                selected: false
            },{
                id:'abcdl2',
                text: '声光控感应带强制点亮端口持续型',
                selected: false
            },{
                id:'abcdl3',
                text: '人体感应带强制点亮端口非持续型',
                selected: false
            },{
                id:'abcdl4',
                text: '雷达感应带强制点亮端口非持续型',
                selected: false
            }]
        },{
            id:'20',
            title:'LED消防应急照明T8灯管',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdm1',
                text: '持续型',
                selected: false
            },{
                id:'abcdm2',
                text: '人体感应带强制点亮端口非持续型',
                selected: false
            },{
                id:'abcdm3',
                text: '雷达感应带强制点亮端口非持续型',
                selected: false
            }]
        },{
            id:'21',
            title:'LED消防应急照明T8灯具',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdn1',
                text: '持续型',
                selected: false
            },{
                id:'abcdn2',
                text: '人体感应带强制点亮端口非持续型',
                selected: false
            },{
                id:'abcdn3',
                text: '雷达感应带强制点亮端口非持续型',
                selected: false
            }]
        },{
            id:'22',
            title:'LED消防应急照明支架T8灯具',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdo1',
                text: '单管(全亮持续型)',
                selected: false
            },{
                id:'abcdo2',
                text: '双管(单管)应急全亮持续型',
                selected: false
            }]
        },{
            id:'23',
            title:'LED消防应急照明三防支架T8灯具',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdp1',
                text: '单管(全亮持续型)',
                selected: false
            },{
                id:'abcdp2',
                text: '双管(单管)应急全亮持续型',
                selected: false
            }]
        },{
            id:'24',
            title:'LED双头消防应急照明壁灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdq1',
                text: '非持续型',
                selected: false
            }]
        },{
            id:'37',
            title:'LED双头消防应急照明灯具',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdq11111',
                text: '非持续型',
                selected: false
            }]
        },{
            id:'25',
            title:'LED消防应急标志灯具',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdr1',
                text: '壁挂式',
                selected: false
            },{
                id:'abcdr2',
                text: '嵌入式',
                selected: false
            },{
                id:'abcdr3',
                text: '吊装式',
                selected: false
            },{
                id:'abcdr4',
                text: '地埋式',
                selected: false
            }]
        },{
            id:'26',
            title:'消防应急控制模块',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcds1',
                text: '外置电源应急微亮',
                selected: false
            },{
                id:'abcds2',
                text: '外置电源应急全亮',
                selected: false
            },{
                id:'abcds3',
                text: '应急模块自带电源应急微亮',
                selected: false
            }]
        },{
            id:'27',
            title:'LED消防应急照明球泡灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdt1',
                text: '声光控感应非持续型',
                selected: false
            },{
                id:'abcdt2',
                text: '人体感应非持续型',
                selected: false
            },{
                id:'abcdt3',
                text: '雷达感应非持续型',
                selected: false
            }]
        },{
            id:'28',
            title:'LED感应球泡灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdu1',
                text: '声光控感应',
                selected: false
            },{
                id:'abcdu2',
                text: '人体感应',
                selected: false
            },{
                id:'abcdu3',
                text: '雷达感应',
                selected: false
            }]
        },{
            id:'29',
            title:'LED感应筒灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdv1',
                text: '人体感应',
                selected: false
            },{
                id:'abcdv2',
                text: '雷达感应',
                selected: false
            }]
        },{
            id:'30',
            title:'LED感应吸顶灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdw1',
                text: '声光控感应',
                selected: false
            },{
                id:'abcdw2',
                text: '人体感应',
                selected: false
            },{
                id:'abcdw3',
                text: '雷达感应',
                selected: false
            }]
        },{
            id:'31',
            title:'LED感应T8灯管',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdx1',
                text: '人体感应',
                selected: false
            },{
                id:'abcdx2',
                text: '雷达感应',
                selected: false
            }]
        },{
            id:'32',
            title:'LED球泡灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'33',
            title:'LED筒灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'34',
            title:'LED吸顶灯',
            showOptions: false,
            selected: false,
            options:[
            ]
        },{
            id:'35',
            title:'LED射灯',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdy1',
                text: '防雾型',
                selected: false
            }]
        },{
            id:'36',
            title:'开关插座、电子节能开关',
            showOptions: false,
            selected: false,
            options:[{
                id:'abcdz1',
                text: 'V1000方尊系列',
                selected: false
            },{
                id:'abcdz2',
                text: '电子节能开关',
                selected: false
            }]
        }],
    }
  },
  methods:{
    openPanel(id){
        let tempArr = []
        this.lists.forEach((item)=>{
            let temp = {}
            temp.id = item.id
            temp.title = item.title
            if(id === item.id){
                temp.showOptions = !item.showOptions
            } else {
                temp.showOptions = item.showOptions
            }
            temp.selected = item.selected
            temp.options = item.options
            tempArr.push(temp)
        })
        this.lists = tempArr
    },
    selectOption(id){
        let tempArr = []
        this.lists.forEach((item)=>{
            let temp = {}
            temp.id = item.id
            temp.title = item.title
            temp.showOptions = item.showOptions
            temp.selected = item.selected
            let openTempArr = []
            item.options.forEach((opt)=>{
                let optemp = {}
                optemp.id = opt.id
                optemp.text = opt.text
                if(id === opt.id){
                    optemp.selected = !opt.selected
                    this.updateSelectedId(id, optemp.selected)
                } else {
                    optemp.selected = opt.selected
                }
                openTempArr.push(optemp)
            })
            temp.options = openTempArr
            tempArr.push(temp)
        })
        this.lists = tempArr
    },
    selectPanel(id){
        let tempArr = []
        this.lists.forEach((item)=>{
            let temp = {}
            temp.id = item.id
            temp.title = item.title
            temp.showOptions = item.showOptions
            if(id === item.id){
                temp.selected = !item.selected
                this.updateSelectedId(id, temp.selected)
            } else {
                temp.selected = item.selected
            }
            temp.options = item.options
            tempArr.push(temp)
        })
        this.lists = tempArr
    },
    updateSelectedId(id, isSelected){
        if(isSelected){
            this.selectedProd.push(id)
        } else {
            this.selectedProd = this.selectedProd.filter((item) => {
                return item !== id
            })
        }
        if(this.selectedProd.length > 0){
            this.isSubmitted = false
        } else if(this.selectedProd.length === 0){
            this.isSubmitted = true
        }
    },
    resetList(){
        this.lists = this.tempList
        this.selectedProd = []
    },
    handleClick(){
        this.isSubmitted = !this.isSubmitted
        this.resetList()
        this.$notify({
                  message: '提交成功',
                  duration: 1000,
                  background: '#ec745b'
        })
    }
  },
}
</script>

<style scoped lang="scss">
.booking-box {
    padding: 50px 10px 90px;
    height: calc(100vh - 140px);
    .list {
      .title {
        padding: 30px 0;
        font-weight: 600;
        font-size: 20px;
        color: #aaa;
      }
      .button-block {
        display:flex;
        justify-content:center;
        margin: 20px 0;
      }
      .pannnel {
        height: 65vh;
        overflow: scroll;
      }
    }
}

</style>