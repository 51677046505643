<template>
    <div class="panel-box" id="panel-box">
        <div v-for="(list) in lists" :key="list.id">
            <div :class="[list.selected ? 'title-selected' : 'title']" @click="openPanel(list.id)" @dblclick="openPanelDB(list.id)">
                <span style="color:rgba(0,0,0,0)">#</span>
                <span>{{ list.title }}</span>
                <div v-if="list.options.length > 0">
                    <img src="../../assets/images/arrow_up.png" alt="" width="16" height="16" v-if="list.showOptions">
                    <img src="../../assets/images/arrow_down.png" alt="" width="16" height="16" v-else>
                </div>
                <div v-else>
                    <span style="color:rgba(0,0,0,0)">#</span>
                </div>
            </div>
            <div :class="[item.selected ? 'option-selected' : 'option']" v-for="(item, index) in list.options" 
                :key="index * 1000" v-show="list.showOptions" :id="item.id" @click="selectOption(item.id)">
                <span style="color:rgba(0,0,0,0)">#</span>
                <span>{{ item.text }}</span>
                <span style="color:rgba(0,0,0,0)">#</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'panel',
    components: {
    },
    mounted(){
        if(this.$store.state.userAgent === 'PC'){
            document.getElementById("panel-box").style.cssText= 'margin-top: 65px;';
        }
    },
    data(){
        return {

        }
    },
    methods:{
        openPanel(id){
            this.$emit('openPanel', id)
            let targetPanel = this.lists.find((list) => {
                return list.id ===  id;
            });
            if(targetPanel.options.length === 0){
                this.$emit('selectPanel', id)
            }
        },
        openPanelDB(id){
            this.$emit('openPanel', id)
            let targetPanel = this.lists.find((list) => {
                return list.id ===  id;
            });
            if(targetPanel.options.length === 0){
                this.$emit('selectPanelDB', id)
            }
        },
        selectOption(id){
            this.$emit('selectOption', id)
        },
    },
    props:{
        lists:{
            type: Array,
            default: ()=>[]
        }
    }
}
</script>

<style scoped lang="scss">
.panel-box {
    max-width: 625px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    .title {
        border-bottom: 1px rgba(0,0,0,0.05) solid;
        padding: 10px 0;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        cursor: default;
        img{
            padding: 2px 10px 0 0;
        }
    }
    .title-selected {
        border-bottom: 1px rgba(0,0,0,0.05) solid;
        background: #faecd8;
        padding: 10px 0;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        cursor: default;
        img{
            padding: 2px 10px 0 0;
        }
    }
    .option {
        padding: 6px 3px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        background: #f3f3f3;
        border: 1px solid rgba(0,0,0,0.02);
    }
    .option-selected{
        padding: 6px 3px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        background: rgb(198, 226, 255);
        border: 1px solid rgba(0,0,0,0.02);
    }
    background: #fdfdfd;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}
</style>
